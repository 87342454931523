import {
  AlignItems,
  Box,
  FontWeight,
  P,
  XYGrid,
  Space,
  ColorPreset,
  H2,
  TypePreset,
  Separator,
} from "@gocardless/flux-react";
import { t, plural } from "@lingui/macro";
import Amount from "src/legacy-ui/amount";
import {
  AvailableDebitSchemeEnum,
  Currency,
} from "@gocardless/api/dashboard/types";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { useLingui } from "@lingui/react";
import { ReactNode, useState } from "react";
import { ToggleButton } from "src/components/shared/ToggleButton";
import { Routes } from "src/components/shared/Router";
import { PaymentVariant } from "src/common/payments";

import { ConsentTypeTag } from "../../../components/ConsentTypeTag";
import {
  IMandateCurrencySelector as CurrencySelectorProps,
  MandateCurrencySelector,
} from "../components/MandateCurrencySelector";

export interface DescriptionProps {
  heading?: string | ReactNode;
  currency: Currency;
  scheme: AvailableDebitSchemeEnum;
  displayedConsentType: string | ReactNode;
  showCurrencySelector?: boolean;
  currencySelectorProps?: CurrencySelectorProps;
  amount?: number;
  recurringPaymentsCount?: number | string;
  periodicityMessage?: ReactNode | string;
  toolTipContent?: string | ReactNode;
  paymentMethodDescription?: string | ReactNode;
  mandateDescription?: string | ReactNode;
  creditorName?: string;
  toggleInstalmentDetails?: () => void;
  page?: Routes;
  paymentVariant?: PaymentVariant;
}

export const DescriptionTemplate = ({
  heading,
  currency,
  scheme,
  amount,
  displayedConsentType,
  toolTipContent,
  creditorName,
  recurringPaymentsCount,
  periodicityMessage,
  mandateDescription,
  paymentMethodDescription,
  showCurrencySelector,
  currencySelectorProps,
  page,
  paymentVariant,
}: DescriptionProps) => {
  const i18n = useLingui();
  const isCustomerDetailsPage = page === Routes.CollectCustomerDetails;
  const renderShowDetailsToggle = paymentVariant === PaymentVariant.DualFlow;
  const [showDetails, setShowDetails] = useState(
    renderShowDetailsToggle ? isCustomerDetailsPage : true
  );

  const contentSections = [
    recurringPaymentsCount,
    periodicityMessage,
    mandateDescription,
    paymentMethodDescription,
  ];

  const onlyOneSectionShowing = contentSections.filter((v) => v).length === 1;

  return (
    <Box>
      <XYGrid rowGap={0.5}>
        {heading && (
          <H2
            data-testid="heading"
            preset={TypePreset.Heading_03}
            weight={FontWeight.SemiBold}
          >
            {heading}
          </H2>
        )}
        {amount && (
          <Box
            data-testid="amount"
            layout="flex"
            alignItems={AlignItems.Center}
          >
            <Amount currency={currency} amountInPence={amount} />
            <Space h={1} layout="inline" />
            <ConsentTypeTag
              displayedConsentType={displayedConsentType}
              toolTipContent={toolTipContent}
            />
          </Box>
        )}
        <Box flexGrow={1} css={{ position: "relative" }}>
          {showDetails && (
            <>
              {mandateDescription && (
                <P
                  preset={TypePreset.Body_01}
                  color={ColorPreset.TextOnLight_01}
                  size={1}
                  spaceBelow={!onlyOneSectionShowing ? 0.5 : 0}
                >
                  {mandateDescription}
                </P>
              )}
              {periodicityMessage && (
                <P
                  data-testid="periodicity-message"
                  size={1}
                  color={ColorPreset.TextOnLight_02}
                  weight={FontWeight.Normal}
                  spaceAbove={mandateDescription ? 0.5 : 0}
                >
                  {periodicityMessage}
                </P>
              )}
              {recurringPaymentsCount && (
                <P
                  data-testid="payments-total"
                  size={1}
                  color={ColorPreset.TextOnLight_02}
                  weight={FontWeight.Normal}
                  spaceAbove={periodicityMessage ? 0.5 : 0}
                >
                  {i18n._(
                    t({
                      id: "plan-description.payment-count-message",
                      comment: "Example: Total of 24 payments",
                      message: `Total of ${plural(recurringPaymentsCount ?? 0, {
                        zero: "# payments",
                        one: "# payment",
                        two: "# payments",
                        few: "# payments",
                        many: "# payments",
                        other: "# payments",
                      })}`,
                    })
                  )}
                </P>
              )}
              {paymentMethodDescription && (
                <>
                  <Separator
                    spacing={[[1, 0]]}
                    color={ColorPreset.BorderOnLight_04}
                  />
                  <XYGrid rowGap={0.5}>
                    <P
                      data-testid="payment-method-description"
                      preset={TypePreset.Body_01}
                      color={ColorPreset.TextOnLight_01}
                      size={1}
                    >
                      {paymentMethodDescription}
                    </P>
                    <P
                      size={1}
                      color={ColorPreset.TextOnLight_02}
                      weight={FontWeight.Normal}
                    >
                      <>Note from {creditorName}</>
                    </P>
                  </XYGrid>
                </>
              )}
            </>
          )}
          {renderShowDetailsToggle && (
            <Box spaceAbove={showDetails ? 0.5 : 0}>
              <ToggleButton
                showDetails={showDetails}
                setNextState={() => setShowDetails(!showDetails)}
              />
            </Box>
          )}
          {!showCurrencySelector && (
            <Box
              data-testid="bank-logo"
              layout="flex"
              alignItems={AlignItems.Center}
              css={{ position: "absolute", bottom: 0, right: 0 }}
              spaceAbove={onlyOneSectionShowing ? 1 : 0}
            >
              {translateForScheme({
                scheme: scheme,
                translationKey:
                  "collect-bank-account-page.bank-details-panel.logo",
                params: {},
              })}
            </Box>
          )}
          {showCurrencySelector && currencySelectorProps && (
            <MandateCurrencySelector {...currencySelectorProps} />
          )}
        </Box>
      </XYGrid>
    </Box>
  );
};
